import { Box, BoxProps, Image, ImageProps, Text, TextProps } from '@chakra-ui/react';
import { AspectRatio } from '@chakra-ui/react';
import React from 'react';

export enum LabelPosition {
    Top,
    Bottom
}

interface ILabelProps extends TextProps {
    borderRadius?: string;
    labelPosition?: LabelPosition;
}

const Label: React.FunctionComponent<ILabelProps> = ({
    children,
    labelPosition = LabelPosition.Top,
    borderRadius = 'lg',
    ...props
}) => (
    <Text
        zIndex={1}
        position="relative"
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        color="gray.500"
        top={labelPosition === LabelPosition.Top ? '0' : 'auto'}
        bottom={labelPosition === LabelPosition.Bottom ? '0' : 'auto'}
        borderTopRadius={labelPosition === LabelPosition.Top ? borderRadius : '0'}
        borderBottomRadius={labelPosition === LabelPosition.Bottom ? borderRadius : '0'}
        fontSize={['8px', '10px']}
        display={{ base: 'none', lg: 'block' }}
        textAlign="center"
        width="full"
        left="0"
        as="span"
        bg="gray.100"
        fontWeight="bold"
        p="1"
        mb={labelPosition === LabelPosition.Top ? '-20px' : '0'}
        mt={labelPosition === LabelPosition.Bottom ? '-20px' : '0'}
        {...props}>
        {children}
    </Text>
);

const Logo: React.FunctionComponent<ImageProps> = (props) => (
    <AspectRatio ratio={1}>
        <div>
            <Image objectFit="contain" w="full" px={[1, 2]} py={[2, 4]} {...props} />
        </div>
    </AspectRatio>
);

interface ISiteBrandComposition {
    Logo: React.FunctionComponent<ImageProps>;
    Label: React.FunctionComponent<ILabelProps>;
}

interface SiteBrandProps extends BoxProps {
    children?: React.ReactNode;
    borderRadius?: string;
    boxShadow?: string;
}

type SiteBrandElementType = ILabelProps | ImageProps;

const SiteBrand: React.FunctionComponent<SiteBrandProps> & ISiteBrandComposition = ({
    children,
    borderRadius = 'lg',
    boxShadow = 'lg',
    ...props
}) => {
    return (
        <Box
            overflow="hidden"
            boxShadow={boxShadow}
            width="100%"
            pt="100%"
            bg="white"
            borderTopRadius={borderRadius}
            borderBottomRadius={borderRadius}
            {...props}
            position="relative"
            as="span"
            display="block">
            <Box
                position="absolute"
                top="0"
                bottom="0"
                width="100%"
                height="100%"
                as="span"
                display="block">
                {React.Children.map(children, (child, index) => {
                    //@ts-ignore
                    if (!React.isValidElement<SiteBrandElementType>(child)) {
                        return child;
                    }
                    const elementChild: React.ReactElement<SiteBrandElementType> = child;
                    //@ts-ignore
                    if (elementChild.type.name === 'Label') {
                        return React.cloneElement(elementChild, {
                            labelPosition: index === 0 ? LabelPosition.Top : LabelPosition.Bottom,
                            borderRadius: borderRadius
                        });
                    }
                    //@ts-ignore
                    else if (elementChild.type.name === 'Logo') {
                        return elementChild;
                    } else {
                        return elementChild;
                    }
                })}
            </Box>
        </Box>
    );
};

SiteBrand.Label = Label;
SiteBrand.Logo = Logo;

export default SiteBrand;
