import { Flex, Spinner, useTheme } from '@chakra-ui/react';
import { motion } from 'framer-motion';

import SiteBrand from './SiteBrand';

type LoaderIconProps = {
    logoUrl: string | undefined | null;
};

const LoaderIcon: React.FunctionComponent<LoaderIconProps> = ({ logoUrl }) => {
    if (logoUrl) {
        return (
            <SiteBrand>
                <SiteBrand.Logo src={logoUrl} />
            </SiteBrand>
        );
    }

    return (
        <Flex bg="white" align="center" justify="center" height={100}>
            <Spinner size="lg" />
        </Flex>
    );
};

const FullLoader: React.FunctionComponent<{}> = () => {
    const { logo } = useTheme();
    const logoUrl = logo || '/examples/logo.png';

    return (
        <Flex
            bg="highlight"
            color="highlightText"
            flexDir="column"
            height="100vh"
            width="100%"
            align="center"
            justify="center">
            <motion.div
                style={{ width: '100px' }}
                animate={{ scale: 1.1 }}
                transition={{
                    duration: 0.4,
                    repeat: Infinity,
                    repeatType: 'reverse',
                    ease: 'easeOut'
                }}>
                <LoaderIcon logoUrl={logoUrl} />
            </motion.div>
        </Flex>
    );
};

export default FullLoader;
